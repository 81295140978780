<template>
	<v-sheet class="inventory" style="height: calc(100vh - 88px)">
		<v-col class="mr-10">
			<v-tabs
				v-model="projectTab"
				background-color="transparent"
				color="blue"
				class="custom-tab-transparent w-100"
				active-class="blue darken-4 text-white"
				hide-slider
				@change="updateProduct()"
			>
				<v-tab href="#all"> Main Product </v-tab>
				<v-tab href="#RequestProduct">Request Product </v-tab>
			</v-tabs>
			<v-row>
				<v-col md="4" class="py-0 my-auto">
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="listingStatus"
						label="Status"
						solo
						v-on:change="filterRows"
					>
						<template v-slot:item="{ item }">
							<v-list-item-action v-if="item.text != 'All Products'">
								<v-chip :color="item.color" :text-color="item.textcolor">
									{{ item.quotation_status_count }}
								</v-chip>
							</v-list-item-action>

							<v-list-item-action v-if="item.text == 'All Products'">
								<v-chip :color="item.color" :text-color="item.textcolor">
									{{ listingStatusCount }}
								</v-chip>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
							</v-list-item-content>
							<!-- <v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.inventory_stock_status_count }}
							</v-chip>
						</v-list-item-action> -->
						</template>
					</v-select>
				</v-col>
				<v-col md="8" class="py-1 my-auto text-right listing-right">
					<template v-if="selected.length > 0">
						<v-menu
							v-model="statusOne"
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon left>mdi-chevron-down</v-icon>
									Bulk Action
								</v-btn>
							</template>
							<v-list class="py-0" v-if="statusOne">
								<template v-for="(item, i) in listingBulkAction">
									<v-list-item link :key="i" v-on:click="doAction(item.value)">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu>
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							v-on:click="clearSelection"
							color="red lighten-1"
							class="text-white"
						>
							<v-icon left> mdi-close </v-icon>
							Clear Selections
						</v-btn>
					</template>
					<template v-else>
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-4"
							class="text-white"
							:to="{ name: 'product-create' }"
							v-if="projectTab == 'all'"
						>
							<v-icon left> mdi-plus </v-icon>
							Create
						</v-btn>
						<v-btn
							v-if="false"
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
						>
							<v-icon>mdi-cog</v-icon>
						</v-btn>
						<v-btn
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
							v-on:click="advanceSearch = true"
						>
							<v-icon>mdi-filter</v-icon>
						</v-btn>
						<v-menu
							v-if="false"
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-menu</v-icon>
								</v-btn>
							</template>
							<v-list class="py-0">
								<template v-for="(item, i) in listingAction">
									<v-list-item link :key="i" v-on:click="doAction(item.value)">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu>
						<v-menu
							v-model="statusTwo"
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
							</template>
							<template v-if="statusTwo">
								<Draggable
									tag="ul"
									v-model="draggableThead"
									class="draggable-group"
									handle=".draggable-drag-icon"
									v-on:change="updateTable('inventory')"
								>
									<template v-for="cols in draggableThead">
										<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
											<v-checkbox
												dense
												v-model="draggableTheadShow"
												v-bind:value="cols.key"
												:label="cols.name"
												:disabled="cols.fixed || pageLoading"
												color="blue"
												hide-details
												class="mt-0 mb-0"
												v-on:change="updateTableVisiblity('inventory')"
											></v-checkbox>
											<v-icon
												v-if="!pageLoading && !cols.fixed"
												class="draggable-action draggable-drag-icon"
												right
												color="blue"
												>mdi-drag</v-icon
											>
											<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
										</li>
									</template>
								</Draggable>
							</template>
						</v-menu>
						<v-btn
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							v-on:click="exportDialogNew = true"
							tile
							depressed
						>
							<v-icon dark left>mdi-database-export</v-icon> Export
						</v-btn>
					</template>
				</v-col>
			</v-row>

			<SearchCriteria
				v-if="searchEnabled()"
				:search-fields="listingFilter"
				search-string="Product #, Name"
				v-on:advance-search="advanceSearch = true"
				v-on:close-search="resetSearch"
			></SearchCriteria>
			<Table
				:page-loading="pageLoading"
				type="product"
				delete-endpoint="inventory/"
				detail-route="product-detail"
				update-route="product-update"
				v-on:reload:content="filterRows"
				delete-note="All transactions of this product will also be deleted."
			></Table>
			<ExportDialog
				endpoint="inventory/export?type=inventory&current=1"
				title="Inventories"
				current
				:export-dialog.sync="exportCurrentDialog"
				v-on:close="exportCurrentDialog = false"
			></ExportDialog>
			<ExportDialog
				endpoint="inventory/export?type=inventory"
				title="Inventories"
				:export-dialog.sync="exportDialog"
				v-on:close="exportDialog = false"
			></ExportDialog>
			<ExportDialogNew
				endpoint="inventory/export?type=inventory"
				title="Inventorys"
				:cols="draggableThead"
				:export-dialog.sync="exportDialogNew"
				v-on:close="exportDialogNew = false"
			></ExportDialogNew>
			<ImportDialog
				endpoint="inventory/import?type=inventory"
				title="Inventories"
				:import-dialog.sync="importDialog"
				v-on:close="importDialog = false"
			></ImportDialog>
			<FilterDialog
				title="Product"
				:filter-dialog.sync="advanceSearch"
				:btx-filter.sync="listingFilter"
				:btx-query.sync="btxQuery"
				v-on:do-search="doAdvanceSearch"
				v-on:close="advanceSearch = false"
			>
			</FilterDialog>
			<v-tabs-items v-model="projectTab">
				<v-tab-item value="MainProduct"> </v-tab-item>
			</v-tabs-items>
			<v-tabs-items v-model="projectTab">
				<v-tab-item value="RequestProduct"> </v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import Table from "../../components/Table.vue";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	components: { Table },
	name: "inventory-listing",
	title: "Listing Products",
	mixins: [ListingMixin],
	data() {
		return {
			projectTab: "MainProduct",
			pageTitle: "Products",
			pageBreadcrumbs: [{ text: "Products", disabled: true }],
			endpoint: "inventory",
			defaultFilter: {},
		};
	},
	methods: {
		updateProduct() {
			this.$router.push({
				query: {
					product_isrpo: this.projectTab,
					t: new Date().getTime(),
				},
			});
			//this.status = this.projectTab;
			this.filterRows();
		},
	},
	beforeDestroy() {
		EventBus.$off("reload:table");
	},
	mounted() {
		EventBus.$on("reload:table", (value) => {
			this.status = value;
			this.filterRows();
		});
	},
};
</script>
